/**
 * Add `toJSON` to ES6 Map, so redux-devtools-extension can display the item changes.
 * see https://github.com/zalmoxisus/redux-devtools-extension/issues/124#issuecomment-221972997
 */
function polyfillMapToJSON() {
    if (process.env.NODE_ENV !== 'production') {
        (Map.prototype as any).toJSON = function() {
            let obj = {} as any;
            this.forEach((value: any, key: any) => (obj[key] = value));
            return obj;
        };
    }
}

function initializeSystem() {
    polyfillMapToJSON();
}

/**
 * The system initialization. It must be called before {@link `../index.tsx`}.
 * see `public/index.html`.
 */
initializeSystem();

/** a hack to disable TS1208: Cannot compile namespaces when the '--isolatedModules' flag is provided. */
export {};
